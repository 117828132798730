import React from "react"

import Layout from "../components/layout"
import Page from "../components/page"
import SEO from "../components/seo"
import Articles from "../components/articles"

const ArticlesPage = () => {
  return (
    <Layout>
      <Page
        title="Articles"
        description="
          If 32 types related content is made, you may find it here.
        "
      >
        <Articles />
      </Page>
      <SEO title="Articles" />
    </Layout>
  )
}

export default ArticlesPage
