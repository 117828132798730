import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "./logo"
import { Container, Row, Col } from 'react-grid-system';
import "./../scss/navbar.scss"

const Navbar = ({ menuLinksLeft, menuLinksRight }) => {
  const menuLinks = (menuLinks, align) => (
    <Container>
      <Row>
        <Col sm={6} style={{
          textAlign: "left",
        }}>
          <Link class="navbar-link" activeClassName="navbar-link" to={menuLinks[0].link}>
            <span style={{
              display: "inline-block",
              verticalAlign: "middle",
              lineHeight: "normal",
            }}>
                {menuLinks[0].name.toUpperCase()}
            </span>
          </Link>
        </Col>
        <Col sm={6} style={{
          textAlign: "right",
        }}>
          <Link class="navbar-link" activeClassName="navbar-link" to={menuLinks[1].link}>
            <span style={{
              display: "inline-block",
              verticalAlign: "middle",
              lineHeight: "normal",
            }}>
                {menuLinks[1].name.toUpperCase()}
            </span>
          </Link>
        </Col>
      </Row>
    </Container>
    // menuLinks.map(link => (
    // ))
  )
  return (
    <nav style={{
      margin: `0 auto`,
      maxWidth: 1280,
      zIndex: 1000,
      height: 0,
    }}>
      <Container fluid={true} style={{
        padding: `1rem`,
        position: "absolute",
        zIndex: 1000,
        marginLeft: "auto",
        marginRight: "auto",
        left: 0,
        right: 0,
        gridColumns: 24
      }}>
        <Row style={{ height: "6rem", lineHeight: "6rem", textAlign: "center" }}>
          <Col sm={2}>
          </Col>
          <Col sm={3}>
            {menuLinks(menuLinksLeft, "right")}
          </Col>
          <Col sm={2} style={{ width: "6rem", lineHeight: "6rem", alignItems: "center" }} >
            <div style={{ width: "6rem", margin: "auto", marginTop: "0.5rem" }}>
              <Link to="/">
                <Logo />
              </Link>
            </div>
          </Col>
          <Col sm={3}>
            {menuLinks(menuLinksRight, "left")}
          </Col>
          <Col sm={2}>
          </Col>
          {/* <Col sm={3}></Col> */}
        </Row>
      </Container>
    </nav>
)}

export default Navbar
