import React from "react"

import Layout from "../components/layout"
import Page from "../components/page"
import SEO from "../components/seo"
import "../scss/cognitive.scss"
import { Container, Row, Col, Hidden } from "react-grid-system"

const FunctionsPage = () => {
  const functionHeader = ({ name, href }) => {
    let symbol = name.split(" ")[1][0] + name.split(" ")[0][0].toLowerCase();

    // quick hack to fix intuition
    symbol = symbol.replace("I", "N")

    return (
      <div class="cognitive-function-symbol-block">
        <a href={href}>
          <span class="cognitive-function-symbol-short">{symbol}</span>
          <span class="cognitive-function-symbol-name ">{name}</span>
        </a>
      </div>
    )
  }

  const functionsBlock = (title, upperLeft, upperRight, downLeft, downRight) => (
    <>
      <span class={"cognitive-functions-axis-title " + title.split(" ").join("-").toLowerCase() + "-title"}>{title}</span>
      <Row class="functions-block">
        <Col sm={1} />
        <Col sm={5}>
          {functionHeader(upperLeft)}
          {functionHeader(downLeft)}
        </Col>
        <Col sm={5}>
          {functionHeader(upperRight)}
          {functionHeader(downRight)}
        </Col>
        <Col sm={1} />
      </Row>
    </>
  )

  return (
    // <div style={{ height: "100%" }}>
    <Layout>
      <Page
        title="Cognitive Functions"
        description={`
          As long as there is motivation, we will update these pages with more information.
          Until then Jung’s descriptions of the functions will shed some light like they did on us years ago.
        `}
      >
        <Container fluid={true}>
          <Row>
            {/* <Col sm={1} /> */}
            <Col sm={6}>
              {functionsBlock("Judging axes",
                { name: "Extroverted Thinking", href: "https://en.wikipedia.org/wiki/Jungian_cognitive_functions#Extroverted_thinking" },
                { name: "Introverted Thinking", href: "https://en.wikipedia.org/wiki/Jungian_cognitive_functions#Introverted_thinking" },
                { name: "Introverted Feeling", href: "https://en.wikipedia.org/wiki/Jungian_cognitive_functions#Introverted_feeling" },
                { name: "Extroverted Feeling", href: "https://en.wikipedia.org/wiki/Jungian_cognitive_functions#Extraverted_feeling" }
              )}
            </Col>
            {/* <Col sm={5} /> */}
            <Col sm={6}>
              {functionsBlock("Perceiving axes",
                { name: "Introverted Sensation", href: "https://en.wikipedia.org/wiki/Jungian_cognitive_functions#Introverted_sensation" },
                { name: "Extroverted Sensation", href: "https://en.wikipedia.org/wiki/Jungian_cognitive_functions#Extroverted_sensation" },
                { name: "Extroverted Intuition", href: "https://en.wikipedia.org/wiki/Jungian_cognitive_functions#Extraverted_intuition" },
                { name: "Introverted Intuition", href: "https://en.wikipedia.org/wiki/Jungian_cognitive_functions#Introverted_intuition" }
              )}
            </Col>
            {/* <Col sm={1} /> */}
          </Row>
        </Container>
        <SEO title="Cognitive Functions" />
      </Page>
    </Layout>
    // </div>
  )
}

export default FunctionsPage
