import React from "react"

import Layout from "../components/layout"
import Page from "../components/page"
import Question from "../components/question"
import SEO from "../components/seo"
import "../scss/test.scss"
import { Container, Row, Col } from "react-grid-system"
// import Styles from './Styles'
import { Field } from 'react-final-form'
import Wizard from '../components/wizard'
import * as questions from '../repository/questions'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const onSubmit = async values => {
  await sleep(300)
  window.alert(JSON.stringify(values, 0, 2))
}

const Error = ({ name }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <span>{error}</span> : null
    }
  />
)

const answers = [[
  `
  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
  Tincidunt vitae semper quis lectus nulla at volutpat.
  Diam vel quam elementum pulvinar etiam non quam lacus suspendisse.
  `, `
  Tortor aliquam nulla facilisi cras fermentum odio eu feugiat pretium.
  Pellentesque diam volutpat commodo sed egestas egestas.
  Facilisi nullam vehicula ipsum a arcu cursus vitae congue mauris.
  `, `
  Vitae auctor eu augue ut lectus.
  Porttitor eget dolor morbi non arcu risus quis.
  Diam quam nulla porttitor massa id neque.
  `, `
  Magna fermentum iaculis eu non.
  Curabitur gravida arcu ac tortor dignissim convallis aenean et.
  At volutpat diam ut venenatis tellus in metus vulputate.
  `,
], [`
  Enim praesent elementum facilisis leo vel fringilla est ullamcorper eget.
  In cursus turpis massa tincidunt dui ut ornare lectus.
  Lectus proin nibh nisl condimentum id venenatis a condimentum vitae.
  `, `
  Amet nisl purus in mollis nunc sed.
  Sed id semper risus in hendrerit gravida rutrum.
  Nullam non nisi est sit amet facilisis.
  `, `
  Velit egestas dui id ornare.
  Nisi est sit amet facilisis magna etiam tempor orci eu.
  Aliquet porttitor lacus luctus accumsan.
  `, `
  Urna condimentum mattis pellentesque id nibh tortor id aliquet lectus.
  Scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus et.
  A pellentesque sit amet porttitor.
  `,
], [`
  Nisl vel pretium lectus quam id leo in.
  Scelerisque viverra mauris in aliquam sem.
  Ornare arcu odio ut sem nulla.
  `, `
  Dictum varius duis at consectetur lorem donec massa.
  Dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in.
  Purus semper eget duis at tellus at.
  `,
], [`
  Amet nulla facilisi morbi tempus iaculis urna id volutpat lacus.
  Eget arcu dictum varius duis at consectetur.
  Est sit amet facilisis magna etiam tempor orci eu lobortis.
  `, `
  Quam lacus suspendisse faucibus interdum posuere.
  Massa vitae tortor condimentum lacinia quis vel eros.
  Nec tincidunt praesent semper feugiat nibh.
  `,
], [`
  Consequat semper viverra nam libero justo laoreet sit amet.
  Quam id leo in vitae turpis massa.
  Nibh tortor id aliquet lectus proin nibh nisl condimentum id.
  `, `
  Tincidunt id aliquet risus feugiat.
  Ornare massa eget egestas purus viverra accumsan in nisl.
  Tempor nec feugiat nisl pretium fusce id velit ut.
  `,
]]

const TestPage = () => {
  return (
    <Layout>
      <Page
        title="Test"
        description={`
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Mauris id urna egestas nunc varius consectetur. Aliquam et bibendum tellus. Donec sed tristique quam.
        `}
      >
        <Container fluid={true} style={{ maxWidth: 1024 }}>
          {/* <Row> */}
          {answers.map((choices, i) => <Question class="question-container" choices={choices} number={i + 1} />)}
          {/* </Row> */}
        </Container>
      </Page>
      <SEO title="Test" />
    </Layout>
    // <Layout>
    //   <Page
    //     title="32 Types Test: Page 1/3"
    //   >
    //     <div style={{
    //       minHeight: "100%",
    //       background: "rgba(14, 13, 12, 0.75)",
    //       maxWidth: 960,
    //       padding: `0px 1.0875rem 1.45rem`,
    //       alignItems: "center",
    //       textAlign: "center",
    //       margin: `0 auto`,
    //       border: "1px solid rgba(255, 255, 255, 0.45)",
    //     }}>
    //       {/* <Wizard onSubmit={onSubmit}>
    //         <Wizard.Page>
    //           {questions.page_1.map(questionBlock)}
    //         </Wizard.Page>
    //         <Wizard.Page>
    //           {questions.page_2.map(questionBlock)}
    //         </Wizard.Page>
    //       </Wizard> */}
    //     </div>
    //     <SEO title="Test" />
    //   </Page>
    // </Layout>
  )
}

export default TestPage
