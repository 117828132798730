export const page_1 = [
{
    question: "Your interest in a social gathering lies in the experience of:",
    answers: [
        "The physical enjoyment.",
        "A potentially stimulating adventure.",
    ]
},
{
    question: "When dealing with a situation, you prefer looking at:",
    answers: [
        "What it is concretely.",
        "Multiple possible interpretations.",
    ]
},
{
    question: "Your type of humor is more often described as:",
    answers: [
        "Down to earth, direct, based on impersonations.",
        "Bizarre, subtle, based on word play.",
    ]
},
{
    question: "When dealing with a personal choice:",
    answers: [
        "You go with what makes sense based on your logical reasons.",
        "You go with what you feel is right.",
    ]
},
{
    question: "Your identity is based on:",
    answers: [
        "What you see yourself as, objectively.",
        "What you feel you are, not simply something that you can define.",
    ]
},
{
    question: "Most commonly when finishing a movie or a TV show, your first impression is based on:",
    answers: [
        "How intriguing it was intellectually.",
        "How it made you feel.",
    ]
},
{
    question: "In every day life, you focus more on:",
    answers: [
        "What happened before.",
        "What might happen in the future.",
    ]
},
{
    question: "When it comes to respect of history and tradition:",
    answers: [
        "You consider it of utmost importance.",
        "You think it should be questioned.",
    ]
},
{
    question: "You trust and rely on:",
    answers: [
        "Known facts.",
        "Patterns.",
    ]
},
{
    question: "Between these two, which one resonates with you more:",
    answers: [
        "Helping humanity.",
        "Being at the head of a successful business.",
    ]
},
{
    question: "The priority in a working environment is:",
    answers: [
        "That everyone is satisfied at the end of the day.",
        "That everyone is performing to the best of their ability.",
    ]
},
{
    question: "When doing something for another person:",
    answers: [
        "You do it out of kindness if you are able.",
        "You do it because you recognize it as something they truly need.",
    ]
},
]

export const page_2 = [
{
    question: "When investigating a situation, you ask questions that cover:",
    answers: [
        "All possibilities, even the improbable ones.",
        "The path that you know is the one.",
    ]
},
{
    question: "When it comes to altering your opinion:",
    answers: [
        "You are willing to consider the alternative.",
        "You are likely to stick with what you perceive as the truth.",
    ]
},
{
    question: "When implementing ideas:",
    answers: [
        "You like to focus on the most attractive idea very deeply.",
        "You like to explore multiple of them at once.",
    ]
},
{
    question: "In order to come up with a solution, you tend to:",
    answers: [
        "Study the principles behind the problem first.",
        "Look for information that will serve you directly.",
    ]
},
{
    question: "When attempting to explain something, you tend to:",
    answers: [
        "Focus on the important information.",
        "Convey things the way you understand them.",
    ]
},
{
    question: "When performing well in a competition:",
    answers: [
        "You desire recognition from others.",
        "You are satisfied with your own acknowledgement.",
    ]
},
{
    question: "When attempting to fix a device:",
    answers: [
        "You take it apart and figure it out.",
        "You research the problem using external sources.",
    ]
},
{
    question: "When it comes to getting rid of physical items:",
    answers: [
        "You have a hard time throwing them away because you might need them.",
        "You have no trouble getting rid of things that you deem unneeded.",
    ]
},
{
    question: "In your daily life:",
    answers: [
        "You like to stick to your routines.",
        "You are more lenient with your routines.",
    ]
},
{
    question: "Expressing your feelings in the heat of the moment is:",
    answers: [
        "Not something you have an issue with as long as it is justified.",
        "Better avoided to protect you from potential consequences.",
    ]
},
{
    question: "Your feelings are more often focused on:",
    answers: [
        "Trying to understand other people.",
        "Understanding yourself.",
    ]
},
{
    question: "When looking at paintings:",
    answers: [
        "You appreciate the artistry but are not often moved deeply.",
        "They have a tendency to provoke a deep feeling.",
    ]
},
]