import React from "react"

import Layout from "../components/layout"
import Page from "../components/page"
import SEO from "../components/seo"
import "../scss/types.scss"
import { Container, Row, Col, Hidden } from "react-grid-system"

const TypesPage = () => {
  const typeHeader = ({ mbti, cfTypes }) => {
    return (
      <div class="type-symbol-block">
        <span class="type-symbol-mbti">{mbti}</span>
        <div>
          <span class="types-symbol-cf">{cfTypes[0]}</span>
          <span class="type-symbol-divider-pipe">|</span>
          <span class="types-symbol-cf">{cfTypes[1]}</span>
        </div>
      </div>
    )
  }

  const typesBlock = ({ cfRange, types }) => (
    <Col sm={6} class="types-block">
      <div class="type-range-title">
        <span class="types-block-title-cf">{cfRange[0][0]}</span>
        <span class="types-range-divider-dash">-</span>
        <span class="types-block-title-cf">{cfRange[0][1]}</span>
        <span class="types-range-divider-pipe">|</span>
        <span class="types-block-title-cf">{cfRange[1][0]}</span>
        <span class="types-range-divider-dash">-</span>
        <span class="types-block-title-cf">{cfRange[1][1]}</span>
      </div>
      <Row>
        {/* <Col sm={1} /> */}
        <Col sm={6}>
          {typeHeader(types[0])}
          {typeHeader(types[2])}
        </Col>
        <Col sm={6}>
          {typeHeader(types[1])}
          {typeHeader(types[3])}
        </Col>
        {/* <Col sm={1} /> */}
      </Row>
    </Col>
  )

  const typeData = [{
    cfRange: [[
      "Se", "Ni",
    ], [
      "Fe", "Ti",
    ]],
    types: [{
      mbti: "ENFJ",
      cfTypes: ["FeNi", "FeSe"],
    }, {
      mbti: "ESTP",
      cfTypes: ["SeTi", "SeFe"],
    }, {
      mbti: "INFJ",
      cfTypes: ["NiFe", "NiTi"],
    }, {
      mbti: "ISTP",
      cfTypes: ["TiSe", "TiNi"],
    }]
  }, {
    cfRange: [[
      "Se", "Ni",
    ], [
      "Te", "Fi",
    ]],
    types: [{
      mbti: "ENTJ",
      cfTypes: ["TeNi", "TeSe"],
    }, {
      mbti: "ESFP",
      cfTypes: ["SeFi", "SeTe"],
    }, {
      mbti: "INTJ",
      cfTypes: ["NiTe", "NiFi"],
    }, {
      mbti: "ISFP",
      cfTypes: ["FiSe", "FiNi"],
    }]
  }, {
    cfRange: [[
      "Ne", "Si",
    ], [
      "Fe", "Ti",
    ]],
    types: [{
      mbti: "ESFJ",
      cfTypes: ["FeSi", "FeNe"],
    }, {
      mbti: "ENTP",
      cfTypes: ["NeTi", "NeFe"],
    }, {
      mbti: "ISFJ",
      cfTypes: ["SiFe", "SiTi"],
    }, {
      mbti: "INTP",
      cfTypes: ["TiNe", "TiSi"],
    }]
  }, {
    cfRange: [[
      "Ne", "Si",
    ], [
      "Te", "Fi",
    ]],
    types: [{
      mbti: "ESTJ",
      cfTypes: ["TeSi", "TeNe"],
    }, {
      mbti: "ENFP",
      cfTypes: ["NeFi", "NeTe"],
    }, {
      mbti: "ISTJ",
      cfTypes: ["SiTe", "SiFi"],
    }, {
      mbti: "INFP",
      cfTypes: ["FiNe", "FiSi"],
    }]
  }]

  return (
    <Layout>
      <Page
        title="Types"
        description={`
          Unfortunately we must continue to use the MBTI type names as they are easily recognized.
          We can only hope that, with time, we are able to move on to addressing types by their top two functions.
        `}
      >
        <Container fluid={true} style={{ maxWidth: 1024 }}>
          <Row class="types-container">
            {typeData.map(typesBlock)}
          </Row>
        </Container>
      </Page>
      <SEO title="Types" />
    </Layout>
  )
}

export default TypesPage
