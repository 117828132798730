import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <div style={{
      textAlign: "center",
      verticalAlign: "middle",
    }}>
      <h1>404 - NOT FOUND</h1>
      <p>Take me <Link to="/">home</Link>.</p>
    </div>
  </Layout>
)

export default NotFoundPage
