import React, { Component } from 'react';
import { Container, Row, Col } from "react-grid-system"

import "./question.scss"
import "./../scss/typography.scss"

// https://stackoverflow.com/a/9083076
function romanize(num) {
    if (isNaN(num))
        return NaN;
    var digits = String(+num).split(""),
        key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
            "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
            "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}

export default class Question extends Component {

    render() {
        return (
            <>
                <Container class={this.props.class} fluid={true}>
                    <h2 class="question-header">{romanize(this.props.number)}</h2>
                    <Row class="choices-container">
                        <Col class="choice" sm={6}>
                            {this.props.choices.filter((_, i) => i % 2 == 0).map(c =>
                                <div class={"choice-text-wrapper choices-" + this.props.choices.length}>
                                    <p class={"choice-text choices-" + this.props.choices.length}>{c}</p>
                                </div>
                            )}
                        </Col>
                        <Col class="choice" sm={6}>
                            {this.props.choices.filter((_, i) => i % 2 == 1).map(c =>
                                <div class={"choice-text-wrapper choices-" + this.props.choices.length}>
                                    <p class={"choice-text choices-" + this.props.choices.length}>{c}</p>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}
