import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../scss/index.scss"
import Articles from "../components/articles"
import Page from "../components/page"
import { Container, Row, Col, Hidden } from "react-grid-system"

import jung from "../images/jung.jpg"

const IndexPage = () => {
  // const data = useStaticQuery(graphql`
  //   query Jung {
  //     file(relativePath: { eq: "jung.jpg" }) {
  //       childImageSharp {
  //         # Specify the image processing specifications right in the query.
  //         fluid(quality: 90, maxWidth: 3840) {
  //           ...GatsbyImageSharpFluid_noBase64
  //         }
  //       }
  //     }
  //   }
  // `)
  // console.log(data)
  return (
  <div>
    <Layout>
      <div style={{
        position: "relative",
        top: 0,
        marginBottom: "-7rem",
      }}>
        {/* <Img className="jung-background" style={{ marginTop: "-10rem" }}
          fluid={data.file.childImageSharp.fluid}
        /> */}
        <div class="video-intro">
          <iframe width="450" height="253" src="https://www.youtube-nocookie.com/embed/b9ZQccng_pM?controls=0&rel=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="take-the-test-wrapper">
          <Link to="/test">
            <button href="/test" className='take-the-test'>Determine your type</button>
          </Link>
        </div>
        <img class="jung-background" src={jung} />
      </div>
      <Page title="Articles">
        <Articles />
      </Page>
      <SEO title="Home" />
    </Layout>
  </div>
  )
}

export default IndexPage
