/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import "./footer.scss"
import "./../scss/typography.scss"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Footer = () => {
  const icons = useStaticQuery(graphql`
    query icons {
      email: file(relativePath: { eq: "email.png" }) {
        childImageSharp {
          fixed(base64Width: 24, width: 24) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      discord: file(relativePath: { eq: "discord.png" }) {
        childImageSharp {
          fixed(base64Width: 24, width: 24) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      youtube: file(relativePath: { eq: "youtube.png" }) {
        childImageSharp {
          fixed(base64Width: 24, width: 24) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <footer>
      <div class="footer-content">
        <div class="footer-icons">
          <a href="mailto:hello@32types.net">
            <Img class="footer-icon" fixed={icons.email.childImageSharp.fixed} />
          </a>
          <a href="https://discord.com/channels/xxxx/yyyy">
            <Img class="footer-icon" fixed={icons.discord.childImageSharp.fixed} />
          </a>
          <a href="https://www.youtube.com/c/32types">
            <Img class="footer-icon" fixed={icons.youtube.childImageSharp.fixed} />
          </a>
        </div>
        <div className="footer-bottom">
          {/* <a href="#">About</a> */}
          <a href="#">Privacy Policy</a>
          {/* <a href="#">Contact</a> */}
        </div>
        <div className="footer-bottom">
          <span>©{new Date().getFullYear()}</span>
        </div>

      </div>
    </footer>
  )
}

export default Footer
