import React from "react"
import PropTypes from "prop-types"

const Articles = () => {
  return (
    <div>
      <div style={{ textAlign: "left", marginBottom: "4rem" }}>
        <a href="#"><h3>Lorem ipsum dolor sit amet</h3></a>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas euismod magna elit. Curabitur sapien neque, consectetur a dui non, faucibus vestibulum leo. In a nisi posuere, mattis justo a, suscipit arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nulla eu consequat ligula. Cras finibus scelerisque ligula id dapibus. Quisque ligula libero, consectetur sit amet pretium ut, dapibus sed nibh. Nam consectetur posuere nisi, vel placerat mi pellentesque eu. Nunc massa ante, rutrum nec erat vitae, iaculis scelerisque turpis. Praesent non ornare enim, ac bibendum turpis.</p>
        <a href="#" style={{ float: "right", marginRight: "3rem" }}>Read more</a>
      </div>
      <div style={{ textAlign: "left", marginBottom: "4rem" }}>
        <a href="#"><h3>Nullam rutrum placerat enim</h3></a>
        <p>Nullam rutrum placerat enim, nec imperdiet arcu gravida porttitor. Sed ipsum diam, luctus quis blandit at, feugiat et ante. Suspendisse lacinia placerat mi, at bibendum sapien faucibus in. Pellentesque venenatis sollicitudin ipsum, quis interdum turpis luctus a. Maecenas semper urna ex, eu vestibulum dui hendrerit sed. Praesent sit amet ultricies ipsum. Morbi ut tincidunt ex. Etiam lorem lorem, mattis dignissim augue nec, viverra sagittis mi. Phasellus convallis dui vel lacinia feugiat. Maecenas ac risus a nisl porttitor vehicula ullamcorper non neque. Etiam tortor eros, posuere quis elementum eu, iaculis nec ante. Pellentesque feugiat non est vitae viverra. Nullam at malesuada orci. Quisque finibus finibus orci ac tempus. Nullam vitae gravida augue. Mauris finibus, arcu eget luctus rhoncus, eros massa mollis ligula, vehicula volutpat velit orci a sem.</p>
        <a href="#" style={{ float: "right", marginRight: "3rem" }}>Read more</a>
      </div>
      <div style={{ textAlign: "left", marginBottom: "4rem" }}>
        <a href="#"><h3>Etiam accumsan massa eu urna tincidunt placerat</h3></a>
        <p>Etiam accumsan massa eu urna tincidunt placerat. Vivamus mi tellus, varius cursus tristique blandit, viverra vitae lorem. Donec cursus dui tincidunt magna volutpat, sed tincidunt felis euismod. Vestibulum accumsan nunc vel augue pharetra accumsan. Etiam ultrices felis eu nunc efficitur, ut efficitur justo facilisis. Etiam odio magna, pellentesque ac vulputate sodales, iaculis quis felis. Nunc sed orci ultricies, scelerisque odio nec, commodo mi. Praesent maximus id purus sit amet facilisis.</p>
        <a href="#" style={{ float: "right", marginRight: "3rem" }}>Read more</a>
      </div>
    </div>
  )
}

export default Articles
