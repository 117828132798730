import React from "react"
import PropTypes from "prop-types"

import "./page.scss"
import "./../scss/typography.scss"

const Page = ({children, title="", description=""}) => {
  return (
    <main style={{
      margin: `0 auto`,
      width: 1280,
      padding: `0px 1.0875rem 1.45rem`,
      alignItems: "center",
      textAlign: "center",
      marginTop: "10rem",
    }}>
        {
          title != "" &&
          <span class="page-header">{title}</span>
        }{
          description != "" &&
          <p class="page-title-description" dangerouslySetInnerHTML={{ __html: description.trim().split("\n").join("<br>") }} />
        }
        <div>
        {children}
        </div>
    </main>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Page
